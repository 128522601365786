import React, {useContext, useState} from "react";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";
import * as TimeZonesUtils from "Services/TimeZonesUtils";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";
import {useFetchUsersQuery} from "api/UserTableAPI";
import {UserCreator} from "./UserCreator";
import {UserEditor} from "./UserEditor";
import {NavLink} from "react-router-dom";
import {UserContext} from "context/UserContext";
import {has} from "lodash";

export const Users = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [userID, setUserID] = useState(null);
    const {user: currentUser, permissions} = useContext(UserContext);
    const canManageUsers = permissions.isAdmin;
    const hasReachedUsersLimit = !currentUser?.has_reached_maximum_users
    let usersColumns = [
        {
            name: "Username",
            accessor: "email",
            id: "userName",
            type: "text",
            render: (user) =>
                <NavLink
                    onClick={() => {
                        setUserID(user.id);
                        setShowEditModal(true);
                    }}
                >
                    {user.formattedValue}
                </NavLink>,
        },
        {
            name: "Title",
            accessor: "role.name",
            id: "userRoleName",
            type: "text",
            value: (value, row) => row.role.name
        },
        {
            name: "Permissions",
            accessor: "group_permission.name",
            id: "userGroupPermission",
            type: "text",
            value: (value, row) => row.group_permission.name
        },
        {
            name: "Status",
            accessor: "is_active",
            id: "accountStatus",
            type: "status",
        },
        {
            name: "Created on",
            accessor: "created_at",
            id: "userCreatedOn",
            type: "date",
        },
    ];

    return (
        <div className="users">
            <GSPSBreadCrumbs />
            <div className="container">
                <GSPSDataDrivenTable
                    columns={usersColumns}
                    title="Users"
                    tableAdminBtn={canManageUsers && <GSPSSubmitButtonStyle
                        onClick={() => setShowAddModal(true)}
                        disabled={hasReachedUsersLimit}
                        className="cta-btn"
                    >
                        Add New User
                    </GSPSSubmitButtonStyle>}
                    useQuery={useFetchUsersQuery}
                />
            </div>
            {showAddModal && (
                <UserCreator
                    show={showAddModal}
                    setShow={setShowAddModal}
                />
            )}
            {showEditModal && userID && (
                <UserEditor
                    show={showEditModal}
                    setShow={setShowEditModal}
                    userID={userID}
                />
            )}
        </div>
    );
};
