import React, {useContext, useState} from "react";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";
import {useFetchAccountsQuery} from "api/AccountTableAPI";
import {AddAccount} from "./AddAccount";
import {Account} from "./Account";
import {NavLink} from "react-router-dom";
import {UserContext} from "context/UserContext";

export const Accounts = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [accountID, setAccountID] = useState(null);
    const {user, permissions} = useContext(UserContext);
    const isAdmin = permissions?.isAdmin;

    let accountColumns = [
        {
            name: "Account",
            accessor: "name",
            id: "accountName",
            type: "text",
            render: (account) => <NavLink
                onClick={() => {
                    setAccountID(account.id);
                    setShowEditModal(true);
                }} value={account.id}>{account.formattedValue}
            </NavLink>
        },
        {
            name: "External Account Contact",
            accessor: "external_account_contact",
            id: "accountContact",
            type: "text",
        },
        {
            name: "Property Type",
            accessor: "account_type.name",
            id: "accountType",
            type: "text",
            value: (value, row) => row.account_type.name
        },
        {
            name: "Account Manager",
            accessor: "manager",
            id: "AccountManager",
            type: "text",
        },
        {
            name: "Status",
            accessor: "is_active",
            id: "accountStatus",
            type: "status",
        }
    ];

    return (
        <div className="accounts">
            <GSPSBreadCrumbs />
            <div className="container">
                {user &&
                    <GSPSDataDrivenTable
                        title="Accounts"
                        columns={accountColumns}
                        tableAdminBtn={
                            isAdmin && <GSPSSubmitButtonStyle
                                onClick={() => setShowAddModal(true)}
                                className="cta-btn"
                            >
                                Add new account
                            </GSPSSubmitButtonStyle>
                        }
                        useQuery={useFetchAccountsQuery}
                    />
                }
            </div>
            {showAddModal && (
                <AddAccount
                    show={showAddModal}
                    setShow={setShowAddModal}
                />
            )}
            {showEditModal && accountID && (
                <Account
                    show={showEditModal}
                    setShow={setShowEditModal}
                    accountID={accountID}
                />
            )}
        </div>
    );
};
