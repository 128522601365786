import React from "react";
import PropTypes from "prop-types";
import {Col, Form} from "react-bootstrap";
import {GSPSContainer, RequiredFormFieldIcon} from "Components/Common/GSPSLabeledInput.styles";
import {useTheme} from "styled-components";

export const GSPSControlledLabelledSelectField = ({
    inputID,
    selectableOptions,
    labelName,
    isBold = false,
    isRequired = false,
    value = "",
    onChangeCB = null,
    errors,
    isInvalid,
    disableAutoSorting
}) => {
    const getErrorMessage = () => {
        return errors && errors[inputID] ? errors[inputID].message : "";
    };
    const sortedOptions = disableAutoSorting ? selectableOptions.sort((a, b) => {
        if (a.display_text < b.display_text) return -1;
        if (a.display_text > b.display_text) return 1;
        return 0;
    }) : selectableOptions;
    const handleChange = (event) => {
        const newValue = event.target.value;
        if (onChangeCB) {
            onChangeCB(newValue);
        }
    };
    const theme = useTheme();
    return (
        <GSPSContainer>
            <label column htmlFor={inputID} className="pb-0 label-sm">
                {isBold ? <strong>{labelName}</strong> : <strong>{labelName}</strong>}:
                {isRequired && <RequiredFormFieldIcon className="--bs-danger">* </RequiredFormFieldIcon>}
            </label>
            <Col>
                <Form.Select
                    as="select"
                    id={inputID}
                    name={inputID}
                    value={value}
                    onChange={handleChange}
                    className="form-select"
                    style={{
                        backgroundColor: theme.color.ctaBg,
                        color: theme.color.labelText,
                    }}
                >
                    <option
                        value="" 
                        style={{ backgroundColor: 'white', color: theme.color.labelText }}>
                            Select</option>
                    {sortedOptions.map((optionObject) => (
                        <option
                            key={optionObject.key}
                            value={optionObject.value} 
                            style={{ backgroundColor: 'white', color: theme.color.labelText }}
                        >
                            {optionObject.display_text}
                        </option>
                    ))}
                </Form.Select>
                {errors && errors[inputID] && (
                    <Form.Control.Feedback type="invalid">
                        {getErrorMessage()}
                    </Form.Control.Feedback>
                )}
            </Col>
        </GSPSContainer>
    );
};

GSPSControlledLabelledSelectField.propTypes = {
    inputID: PropTypes.string.isRequired,
    labelName: PropTypes.string.isRequired,
    isBold: PropTypes.bool,
    selectableOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        display_text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })),
    isRequired: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangeCB: PropTypes.func,
    errors: PropTypes.object,
    isInvalid: PropTypes.bool,
};
