import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {DataGrid} from "@mui/x-data-grid";
import {Toolbar, Typography} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import GSPSDataTablePagination from "./DataTablePagination";
import {useTheme} from "styled-components";
import {useIsDesktop} from "Services/ServicesUtils";
import * as TimeZonesUtils from "Services/TimeZonesUtils";
import {createIsFilterOperator} from "./TableFilterOperators";

const GSPSDataDrivenTable = ({
    columns = [],
    useQuery = null,
    checkboxSelection = false,
    title = "",
    tableAdminBtn = null,
    defaultPageSize = 25,
    disableColumnResize = false,
    disableColumnMenu = false,
    uniqueTableID,
    rowHeight,
    active,
}) => {
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);
    const [filters, setFilters] = useState([]);
    const theme = useTheme();
    const isDesktop = useIsDesktop();

    const paginationModel = {
        page: currentPageIndex - 1,
        pageSize: currentPageSize,
    };

    const {data, totalRowCount, isLoading, refetch} = useQuery({
        page: currentPageIndex,
        pageSize: currentPageSize,
        filters,
        ...(active !== undefined && {active}),
    }, {
        selectFromResult: ({data, isLoading, refetch}) => ({
            data: data?.data || [],
            totalRowCount: data?.totalRowCount || 0,
            isLoading,
            refetch,
        }),
    });

    // Map columns to work with MUI table
    const mapColumns = (columns) => {
        return columns.map((col) => {
            switch (col.type) {
                case "action":
                    if (col.id !== "btn") {
                        return {
                            field: col.id,
                            width: 39,
                            type: "actions",
                            renderCell: col.render,
                        };
                    } else {
                        return {
                            field: col.id,
                            headerName: col.name,
                            width: 100,
                            renderCell: col.render,
                            sortable: false,
                            filterable: false,
                            disableColumnMenu: true,
                        };
                    }

                case "status":
                    return {
                        field: col.accessor,
                        headerName: col.name,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        renderCell: (params) => {
                            const status = params.formattedValue;
                            const color = status === "Enabled" ? "#2FC0CF" : "#EB4B53";
                            return (
                                <div>
                                    <CircleIcon size="small" style={{color: color}} className="me-2" />
                                    <span>{status}</span>
                                </div>
                            );
                        },
                        valueGetter: (params) => params ? "Enabled" : "Disabled",
                        filterOperators: [
                            createIsFilterOperator([
                                {label: "Enabled", value: "true"},
                                {label: "Disabled", value: "false"}
                            ]),
                        ],
                    };

                case "date":
                    return {
                        field: col.accessor,
                        headerName: col.name,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        valueGetter: (params) => {
                            return TimeZonesUtils.convertToEst((params.toString()), true);
                        },
                        filterable: false,
                        disableColumnMenu: true,
                    };

                default:
                    return {
                        field: col.accessor,
                        headerName: col.name,
                        ...(isDesktop ? {flex: 1} : {width: 200}),
                        renderCell: col.render,
                        valueGetter: col.value,
                        valueFormatter: (value) => value || "-",
                        filterOperators: col.filterOperators
                    };
            }
        });
    };

    const mappedColumns = mapColumns(columns);

    const handlePaginationModelChange = (page, pageSize) => {
        setCurrentPageIndex(page + 1);
        setCurrentPageSize(pageSize);
    };

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleFilterChange = (filterModel) => {
        const filters = filterModel.items.length ? filterModel.items.reduce((acc, item) => {
            acc[item.field] = item.value ? item.value : "";
            return acc;
        }, {}) : {};
        setFilters(filters);
    };

    return (
        <>
            <div className="position-relative my-3">
                <div style={{width: "100%"}}>
                    <Toolbar className="px-3" sx={{
                        backgroundColor: theme.color.secondaryBg,
                        border: `2px solid ${theme.color.border}`,
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px"
                    }}>
                        <Typography
                            sx={{flex: "1 1 100%"}}
                            variant="h3"
                            id="title"
                            component="h3"
                            className="subtitle-lg"
                        >
                            {title}
                        </Typography>
                        <div className="tableAdminBtn">
                            {tableAdminBtn}
                        </div>
                    </Toolbar>
                    {
                        <DataGrid
                            rows={data}
                            columns={mappedColumns}
                            getRowId={uniqueTableID}
                            getRowHeight={() => rowHeight}
                            autoHeight
                            loading={isLoading}
                            rowCount={totalRowCount}
                            disableColumnResize={disableColumnResize}
                            disableColumnMenu={disableColumnMenu}
                            disableRowSelectionOnClick
                            pagination
                            slots={{
                                pagination: GSPSDataTablePagination,
                            }}
                            slotProps={{
                                loadingOverlay: {
                                    variant: "skeleton",
                                    noRowsVariant: "skeleton",
                                },
                            }}
                            pageSizeOptions={[5, 10, 25, 50, 100]}
                            paginationModel={paginationModel}
                            paginationMode="server"
                            filterMode="server"
                            onFilterModelChange={handleFilterChange}
                            onPaginationModelChange={(e) => handlePaginationModelChange(e.page, e.pageSize)}
                            checkboxSelection={checkboxSelection}
                            getCellClassName={() => 'body-md'}
                            sx={
                                {
                                    "border": `2px solid ${theme.color.border}`,
                                    "& .MuiDataGrid-columnHeaderTitleContainer": {
                                        fontSize: "16px"
                                    },
                                    "& .MuiDataGrid-row": {
                                        minHeight: "52px !important"
                                    },
                                    "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                                        backgroundColor: `${theme.color.secondaryBg} !important`,
                                    },
                                    "& .MuiTablePagination-displayedRows": {
                                        marginBottom: "0px !important",
                                    },
                                    "& .MuiTablePagination-selectLabel": {
                                        margin: "0",
                                    },
                                    "& .MuiDataGrid-cell--textLeft a": {
                                        color: "#4CA2A8 !important",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        textTransform: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        textTransform: "capitalize",
                                    },
                                }}
                        />
                    }
                </div>
            </div>
        </>
    );
};


GSPSDataDrivenTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            accessor: PropTypes.string,
            id: PropTypes.string,
            type: PropTypes.oneOf(["text", "action", "status", "date"]),
            render: PropTypes.func,
            value: PropTypes.func,
            filterOperators: PropTypes.array
        })
    ).isRequired,
    useQuery: PropTypes.func.isRequired,
    checkboxSelection: PropTypes.bool,
    title: PropTypes.string,
    tableAdminBtn: PropTypes.node,
    defaultPageSize: PropTypes.number,
    disableColumnResize: PropTypes.bool,
    disableColumnMenu: PropTypes.bool,
    uniqueTableID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    rowHeight: PropTypes.string
};

export {GSPSDataDrivenTable};