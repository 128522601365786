import {MenuItem, Select} from "@mui/material";

export const createIsFilterOperator = (options) => ({
    label: 'is',
    value: 'is',
    getApplyFilterFn: (filterItem) => {
        if (!filterItem.value) {
            return null;
        }
        return ({value}) => {
            return value === filterItem.value;
        };
    },
    InputComponent: (props) => (
        <Select
            onChange={(event) => props.applyValue({...props.item, value: event.target.value})}
            displayEmpty
            value={props.item.value || ""}
            className="position-absolute w-100 bottom-0"
        >
            <MenuItem disabled value="">
                <em>Select</em>
            </MenuItem>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    ),
});