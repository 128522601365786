import React, {useState} from "react";
import {useTheme} from "styled-components";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";
import {useFetchLocationsQuery} from "api/LocationTableAPI";
import {AddLocation} from "./AddLocation";
import {Location} from "./Location";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";
import {NavLink, useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {UserContext} from "context/UserContext";
import {useContext} from "react";

export const Locations = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [locationID, setLocationID] = useState(null);
    const {user, permissions} = useContext(UserContext);
    const isAdmin = permissions.isAdmin;
    const theme = useTheme();
    const navigate = useNavigate();

    let locationsColumns = [
        {
            name: "Address",
            accessor: "full_address",
            id: "locationsAddress",
            type: "text",
            render: (location) => <NavLink
                onClick={() => {
                    setLocationID(location.id);
                    setShowEditModal(true);
                }}
            >
                {location.row.full_address}
            </NavLink>,
        },
        {
            name: "Account",
            accessor: "account_name",
            id: "locationAccount",
            type: "text",
        },
        {
            name: "Property Type",
            accessor: "status_type.name",
            id: "locationStatusName",
            type: "text",
            value: (value, row) => row.status_type?.name
        },
        {
            name: "Account Manager",
            accessor: "account_manager",
            id: "locationAccountManager",
            type: "text",
        },
        {
            name: "Status",
            accessor: "is_active",
            id: "locationStatus",
            type: "status",
        }
    ]

    if (isAdmin) {
        locationsColumns = [
            ...locationsColumns, {
                name: "Action",
                id: "btn",
                type: "action",
                render: (location) => <Button
                    variant={"outlined"}
                    onClick={() => navigate(`/audits/creator?locationId=${location.id}`)} disabled={!location.row.is_active}
                    sx={{
                        "color": theme.color.textOnBg,
                        "borderColor": theme.color.textOnBg,
                        "textTransform": "capitalize",
                        "&:hover": {
                            borderColor: "#02A783",
                        },
                    }}>
                    Audit
                </Button>
            }
        ]
    }

    return (
        <div className="locations">
            <GSPSBreadCrumbs />
            <div className="container">
                {
                    user && user?.subscriber &&
                    <GSPSDataDrivenTable
                        columns={locationsColumns}
                        title="Locations"
                        tableAdminBtn={
                            isAdmin && <GSPSSubmitButtonStyle
                                onClick={() => setShowAddModal(true)}
                                className="cta-btn"
                            >
                                Add new location
                            </GSPSSubmitButtonStyle>
                        }
                        useQuery={useFetchLocationsQuery}
                    />
                }
            </div>
            {showAddModal && (
                <AddLocation
                    show={showAddModal}
                    setShow={setShowAddModal}
                />
            )}
            {showEditModal && locationID && (
                <Location
                    show={showEditModal}
                    setShow={setShowEditModal}
                    locationID={locationID}
                />
            )}
        </div>
    );
};
