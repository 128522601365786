import React, {useEffect, useState} from "react";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {fetchSelectionOptions, makeGetRequest, useIsDesktop} from "Services/ServicesUtils";
import {GSPSToolbarSelectField} from "Components/Common/GSPSToolbarSelectField";
import {ToolbarContainer} from "Pages/Audits/AuditMapToolbar.styles";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {DatePicker} from "@mui/x-date-pickers";
import {Backdrop, Button, CircularProgress, Toolbar, Typography} from "@mui/material";
import {insertNotification} from "Utils/NotificationsUtils";
import {DatePickerContainer} from "Components/Common/DatePicker.styles";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {DataGrid} from "@mui/x-data-grid";
import {useTheme} from "styled-components";
import GetAppIcon from '@mui/icons-material/GetApp';
import * as XLSX from "xlsx";
import GSPSPropDrivenTable from "Components/DataTable/GSPSPropDrivenTable";
export const Report = () => {
    const [accounts, setAccounts] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationsLoading, setLocationsLoading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [resetDropdown, setResetDropdown] = useState(false);
    const [fromDate, setFromDate] = useState("2024-01-01");
    const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10)); // now date

    const [accountAreas, setAccountAreas] = useState([]);
    const [accountLines, setAccountLines] = useState([]);
    const [accountIssues, setAccountIssues] = useState([]);
    const [allData, setAllData] = useState(false);
    const [loading, setLoading] = useState(false);

    const isDesktop = useIsDesktop();
    const theme = useTheme();

    const reportColumns = [
        {
            field: 'id',
            headerName: 'ID',
            ...(isDesktop ? {flex: 1} : {width: 200}),
        },
        {
            field: 'audit_name',
            headerName: 'Audit Name',
            ...(isDesktop ? {flex: 2} : {width: 200}),
        },
        {
            field: 'audit_address',
            headerName: 'Audit Address',
            ...(isDesktop ? {flex: 2} : {width: 300}),
        },
        {
            field: 'audit_type_item',
            headerName: 'Type',
            ...(isDesktop ? {flex: 2} : {width: 200}),
            valueGetter: (params) => params.name
        },
    ];

    const formatDate = (date) => {
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        const formattedDate = new Date(date).toLocaleDateString("en-CA", options); // "en-CA" gives ISO format (YYYY-MM-DD)
        return formattedDate;
    };
    const adjustedFromDate = formatDate(fromDate);
    const adjustedToDate = formatDate(toDate);

    useEffect(() => {
        fetchSelectionOptions(API_ACCOUNTS.accountsDropdown, setAccounts);
    }, []);

    useEffect(() => {
        if (selectedAccount) {
            try {
                setLocationsLoading(true);
                if (selectedAccount.value !== "ALL") {
                    fetchSelectionOptions(API_LOCATIONS.accountLocations(selectedAccount.value),
                        setLocations,
                        "address.full_address");
                    setResetDropdown(true);
                    setSelectedLocation(null);
                }
                else {
                    setResetDropdown(true);
                    setLocations(null);
                    setSelectedLocation(null);
                }
            }
            finally {
                setLocationsLoading(false);
            }
        }
    }, [selectedAccount]);

    const handleAccountSelection = (account) => {
        if (account && account.value != 0) {
            setSelectedAccount(account);
        } else {
            setSelectedAccount(null);
        }
    };

    const handleLocationSelection = (location) => {
        if (location && location.value != 0) {
            setResetDropdown(false);
            setSelectedLocation(location);
        } else {
            setSelectedLocation(null);
        }
    };

    const exportToExcel = (data) => {
        const rows = [];
        data.forEach((account) => {
            [["audit_issues", "Issue"], ["audit_lines", "Line"], ["audit_areas", "Area"]].forEach(type => {
                account[type[0]].forEach((item) => {
                    rows.push({
                        "Type": type[1],
                        "Audit Name": item.audit_name,
                        "Audit Type": item.audit_type_item.audit_type.name,
                        "Audit Type Item": item.audit_type_item.name,
                        "Location": item.audit_address,
                        "Account": account.name,
                        "Account Admin": account.first_account_admin,
                    });
                });
            });
        });
        const ws = XLSX.utils.json_to_sheet(rows);
        const defaultColWidth = 20;
        ws["!cols"] = Array(Object.keys(rows[0]).length).fill({wch: defaultColWidth});
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "table_data.xlsx");
    };

    async function handleSubmit() {
        const params = {
            account_id: selectedAccount ? selectedAccount.value === 'ALL' ? selectedAccount.value : Number(selectedAccount.value) : null,
            location_id: selectedLocation ? selectedLocation.value === 'ALL' ? selectedLocation.value : Number(selectedLocation.value) : null,
            from_date: adjustedFromDate,
            to_date: adjustedToDate,
        };
        const hasNullValues = Object.entries(params)
            .filter(([key]) => key !== 'location_id')
            .some(([, value]) => value === null);
        if (!hasNullValues) {
            const queryString = new URLSearchParams(params).toString();
            setLoading(true);
            makeGetRequest(`${API_ACCOUNTS.accountsWithItems}?${queryString}`)
                .then(
                    (res) => {
                        setAccountAreas(res[0].audit_areas);
                        setAccountLines(res[0].audit_lines);
                        setAccountIssues(res[0].audit_issues);
                        setAllData(res);
                    })
                .catch((error) => {
                    insertNotification("Error", `${error}`, "error");
                    dataFetched(false);
                }).finally(() => {
                    setLoading(false);
                })
        }
        else {
            insertNotification("Error", "Please make sure all inputs are filled and try again.", "error");
        }
    }

    return (
        <div>
            <GSPSBreadCrumbs />
            <div className="container">
                <ToolbarContainer>
                    <div className="row mb-3">
                        <div className="col-6 d-flex justify-content-between align-items-center my-3">
                            {accounts &&
                                <GSPSToolbarSelectField
                                    defaultValue={{key: "DefaultValue", value: 0, display_text: "Account"}}
                                    inputID={"account"}
                                    selectableOptions={accounts.length > 1 ? [{key: "ALL", value: "ALL", display_text: "All"}, ...accounts] : accounts}
                                    labelName="Account"
                                    onChangeCB={(value) => {
                                        handleAccountSelection(value);
                                    }}
                                    variant="outlined"
                                />}
                        </div>
                        <div className="col-6 d-flex justify-content-between align-items-center my-3">
                            {locations && locations.length > 0 &&
                                <GSPSToolbarSelectField
                                    defaultValue={{key: "DefaultValue", value: "A&&", display_text: "Select"}}
                                    DropDownReset={resetDropdown}
                                    inputID={"location"}
                                    selectableOptions={locations.length > 1 ? [{key: "ALL", value: "ALL", display_text: "All"}, ...locations] : locations}
                                    labelName="Location"
                                    onChangeCB={(value) => {
                                        handleLocationSelection(value);
                                    }}
                                    variant="outlined"
                                />}
                            {locationsLoading && <CircularProgress size={20} color="inherit" />}
                        </div>
                        <div className="col-4 d-flex justify-content-between align-items-center">
                            <DatePickerContainer>
                                <DatePicker
                                    label={"Start Date"}
                                    startDate={new Date("2024-01-01")}
                                    minDate={new Date("2024-01-01")}
                                    disableFuture
                                    slotProps={{
                                        textField: {
                                            helperText: 'MM/DD/YYYY',
                                        },
                                    }}
                                    onChange={(date) => setFromDate(date)}
                                />
                            </DatePickerContainer>
                        </div>
                        <div className="col-4 d-flex justify-content-between align-items-center">
                            <DatePickerContainer>
                                <DatePicker
                                    label={"End Date"}
                                    startDate={fromDate}
                                    disableFuture
                                    minDate={fromDate}
                                    slotProps={{
                                        textField: {
                                            helperText: 'MM/DD/YYYY',
                                        },
                                    }}
                                    onChange={(date) => setToDate(date)}
                                />
                            </DatePickerContainer>
                        </div>
                        <div className="col-4 d-flex justify-content-end align-items-center">
                            <GSPSSubmitButton
                                isDisabled={false}
                                controlSize={2}
                                offset={0}
                                buttonText={"Submit"}
                                onClick={handleSubmit}
                                colClassName="w-25 mb-3"
                            />
                        </div>
                    </div>
                </ToolbarContainer>
                {allData.length > 0 &&
                    <>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button variant="outlined" className="mt-4 cta-btn" endIcon={<GetAppIcon />}
                                onClick={() => exportToExcel(allData)}
                                sx={{
                                    color: theme.color.dark,
                                    borderColor: theme.color.dark,
                                    textTransform: "capitalize",
                                    ":hover": {
                                        borderColor: theme.color.info
                                    }
                                }}
                            >
                                Export
                            </Button>
                        </div>
                        <div className="my-5">
                            <GSPSPropDrivenTable
                                title={"Issues"}
                                data={accountIssues}
                                columns={reportColumns}
                                loading={loading}
                                disableColumnResize
                                disableColumnMenu
                            />
                        </div>
                        <div className="my-5">
                            <GSPSPropDrivenTable
                                title="Lines"
                                data={accountLines}
                                columns={reportColumns}
                                loading={loading}
                                disableColumnResize
                                disableColumnMenu
                            />
                        </div>
                        <div className="my-5">
                            <GSPSPropDrivenTable
                                title="Areas"
                                data={accountAreas}
                                columns={reportColumns}
                                loading={loading}
                                disableColumnResize
                                disableColumnMenu
                            />
                        </div>
                    </>
                }
                <Backdrop
                    sx={{color: '#fff', zIndex: "555"}}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    );
};
