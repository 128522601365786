import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import * as TimeZonesUtils from "Services/TimeZonesUtils";

// Define styles for the PDF
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    nav: {
        borderBottom: "3px solid gray",
        paddingBottom: "5px",
        marginBottom: 20
    },
    section: {
        marginBottom: 10,
        marginTop: 10,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flex: {
        display: "flex",
    },
    title: {
        fontSize: 21,
        marginBottom: 10,
    },
    subTitle: {
        fontSize: 16,
        marginBottom: 5,
        paddingBottom: 5
    },
    headline: {
        fontSize: 25,
        marginBottom: 10,
        fontWeight: 700
    },
    table: {
        border: "2px solid #dbe6e2",
        borderRadius: "10px",
        padding: "10px",
        marginVertical: "10px"
    },
    titleWithBorder: {
        borderBottom: "2px solid #dbe6e2",
        marginVertical: 10,
        paddingBottom: 5
    },
});

// Audit Report Document Component
const AuditReportDocument = ({audit, mapImage}) => {
    console.log(audit);
    const currentDate = new Date();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* start nav */}
                <View style={styles.nav}>
                    <Text style={styles.title}>
                        Audit: {audit.name}
                    </Text>
                    <Text>{audit.location.address.full_address}</Text>
                </View>
                {/* end nav */}


                {mapImage && (
                    <View style={styles.section}>
                        <Text style={styles.title}>Map</Text>
                        <Image src={mapImage} source={mapImage} />
                    </View>
                )}
            </Page>

            <Page size="A4" style={styles.page}>
                {/* start nav */}
                <View style={styles.nav}>
                    <Text style={styles.title}>
                        Audit: {audit.name}
                    </Text>
                    <Text>{audit.location.address.full_address}</Text>
                </View>
                {/* end nav */}

                {/* start issue table */}
                {
                    audit.audit_issues.length && <View style={styles.section}>
                        <Text style={styles.headline}>Issues</Text>
                        <View>
                            {audit.audit_issues.map((issue, index) => {
                                return <View key={issue.id} style={styles.table}>
                                    <Text style={[styles.subTitle, styles.titleWithBorder]}>{index + 1}- {issue.audit_type_item.name} | {issue.severity_object.name || "N/A"}</Text>

                                    {
                                        issue.description && <Text style={{marginBottom: 5}}>
                                            {issue.description}
                                        </Text>
                                    }

                                    <View>
                                        {issue.issue_images.length ? <View style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            {issue.issue_images.map((image) => {
                                                return <Image key={image.image_sequence} src={image.image_value} style={{width: "50%", paddingHorizontal: 10}} />
                                            })}
                                        </View> : <Text style={{paddingVertical: 10}}>This issue has no images</Text>}
                                    </View>
                                </View>
                            })}
                        </View>
                    </View>
                }
                {/* end issue table */}

                {/* start line table */}
                {
                    audit.audit_lines.length && <View style={styles.section}>
                        <Text style={styles.headline}>Lines</Text>
                        <View>
                            {audit.audit_lines.map((line, index) => {
                                return <View key={line.id} style={styles.table}>
                                    <Text style={[styles.subTitle, styles.titleWithBorder]}>{index + 1}- {line.audit_type_item.name}</Text>

                                    {
                                        line.description && <Text style={{marginBottom: 5}}>
                                            {line.description}
                                        </Text>
                                    }

                                    <View>
                                        {line.line_images.length ? <View style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            {line.line_images.map((image) => {
                                                return <Image key={image.image_sequence} src={image.image_value} style={{width: "50%", paddingHorizontal: 10}} />
                                            })}
                                        </View> : <Text style={{paddingVertical: 10}}>This line has no images</Text>}
                                    </View>
                                </View>
                            })}
                        </View>
                    </View>
                }
                {/* end line table */}

                {/* start area table */}
                {
                    audit.audit_areas.length && <View style={styles.section}>
                        <Text style={styles.headline}>Areas</Text>
                        <View>
                            {audit.audit_areas.map((area, index) => {
                                return <View key={area.id} style={styles.table}>
                                    <Text style={[styles.subTitle, styles.titleWithBorder]}>{index + 1}- {area.audit_type_item.name}</Text>

                                    {
                                        area.description && <Text style={{marginBottom: 5}}>
                                            {area.description}
                                        </Text>
                                    }

                                    <View>
                                        {area.area_images.length ? <View style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row"
                                        }}>
                                            {area.area_images.map((image) => {
                                                return <Image key={image.image_sequence} src={image.image_value} style={{width: "50%", paddingHorizontal: 10}} />
                                            })}
                                        </View> : <Text style={{paddingVertical: 10}}>This area has no images</Text>}
                                    </View>
                                </View>
                            })}
                        </View>
                    </View>
                }
                {/* end area table */}
            </Page>

            {
                audit.answers.length &&
                <Page size="A4" style={styles.page}>
                    {/* start nav */}
                    <View style={styles.nav}>
                        <Text style={styles.title}>
                            Audit: {audit.name}
                        </Text>
                        <Text>{audit.location.address.full_address}</Text>
                    </View>
                    {/* end nav */}

                    <Text style={styles.title}>
                        Questions & Answers
                    </Text>
                    <View style={styles.section}>
                        {
                            audit.answers.map((question) => <View style={styles.titleWithBorder} key={question.id}>
                                <Text style={styles.title}>
                                    {question.question_text}
                                </Text>
                                <Text>
                                    answer: {question.answer_text}
                                </Text>
                            </View>)
                        }
                    </View>
                </Page>
            }

            <Page size="A4" style={styles.page}>
                {/* start nav */}
                <View style={styles.nav}>
                    <Text style={styles.title}>
                        Audit: {audit.name}
                    </Text>
                    <Text>{audit.location.address.full_address}</Text>
                </View>
                {/* end nav */}

                {/* start reviewer info  */}
                <View style={styles.table}>
                    <Text style={styles.titleWithBorder}>
                        Reviewer Information
                    </Text>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[styles.subTitle, {marginRight: 5}]}>Name:</Text>
                        <Text style={styles.subTitle}>{audit.reviewer.full_name}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[styles.subTitle, {marginRight: 5}]}>Phone:</Text>
                        <Text style={styles.subTitle}>{audit.reviewer.mobile_phone || "N/A"}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[styles.subTitle, {marginRight: 5}]}>Email:</Text>
                        <Text style={styles.subTitle}>{audit.reviewer.email || "N/A"}</Text>
                    </View>
                </View>
                {/* end reviewer info  */}

                {/* start location info  */}
                <View style={styles.table}>
                    <Text style={styles.titleWithBorder}>
                        Location Information
                    </Text>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[styles.subTitle, {marginRight: 5}]}>Name:</Text>
                        <Text style={styles.subTitle}>{audit.location.location || "N/A"}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[styles.subTitle, {marginRight: 5}]}>Address:</Text>
                        <Text style={styles.subTitle}>{audit.location.address.full_address || "N/A"}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[styles.subTitle, {marginRight: 5}]}>Contact:</Text>
                        <Text style={styles.subTitle}>{audit.location.contact_name || "N/A"}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[styles.subTitle, {marginRight: 5}]}>Audit Created:</Text>
                        <Text style={styles.subTitle}>{TimeZonesUtils.convertToEst((audit.created_at.toString()), true)}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[styles.subTitle, {marginRight: 5}]}>Report Generated:</Text>
                        <Text style={styles.subTitle}>{TimeZonesUtils.convertToEst((currentDate.toString()), true)}</Text>
                    </View>
                </View>
                {/* end location info  */}
            </Page>
        </Document>
    );
};

export default AuditReportDocument;
