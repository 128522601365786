import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {auditRequests} from "app/requests/auditRequests";
import {makeGetRequest} from "Services/ServicesUtils";

const getLocalStorageData = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
};

const setLocalStorageData = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const fetchAllData = createAsyncThunk(
    "data/fetchAllData",
    async (_, thunkAPI) => {
        try {
            const cachedData = getLocalStorageData("auditData");
            if (cachedData) {
                return cachedData;
            }
            const results = await Promise.allSettled(auditRequests.map((request) => makeGetRequest(request.endpoint)));
            const dataToCache = auditRequests.reduce((acc, request, index) => {
                acc[request.key] = results[index]?.value || [];
                return acc;
            }, {});

            setLocalStorageData("auditData", dataToCache);
            return dataToCache;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

const initialState = {
    severities: [],
    questions: [],
    auditTypes: [],
    auditStatuses: [],
    auditsTypesItems: [],
    users: [],
    statusTypes: [],
    accountsDropdown: [],
    status: "idle",
    error: null,
};

const dataSlice = createSlice({
    name: "data",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.severities = action.payload.severities;
                state.questions = action.payload.questions;
                state.auditTypes = action.payload.auditTypes;
                state.auditStatuses = action.payload.auditStatuses;
                state.auditsTypesItems = action.payload.auditsTypesItems;
                state.users = action.payload.users;
                state.statusTypes = action.payload.statusTypes;
                state.accountsDropdown = action.payload.accountsDropdown;
            })
            .addCase(fetchAllData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});

export default dataSlice.reducer;
